@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
:root {
  --white-color: #fff;
  --blue-color: #4070f4;
  --grey-color: #707070;
  --grey-color-light: #aaa;
}
.bg-admin{
  transition: all 0.5s ease;
  background-color: #e7f2fd;
}
body.dark {
  background-color: #333;
}
body.dark {
  --white-color: #333;
  --blue-color: #fff;
  --grey-color: #f2f2f2;
  --grey-color-light: #aaa;
}
/* navbar */
.navbar.admin-navbar {
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  background-color: var(--white-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 30px;
  z-index: 1000;
  box-shadow: 0 0 2px var(--grey-color-light);
}
.logo_item {
  display: flex;
  align-items: center;
  column-gap: 10px;
  font-size: 22px;
  font-weight: 500;
  color: var(--blue-color);
}
.admin-navbar img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}
.search_bar {
  height: 47px;
  max-width: 430px;
  width: 100%;
}
.search_bar input {
  height: 100%;
  width: 100%;
  border-radius: 25px;
  font-size: 18px;
  outline: none;
  background-color: var(--white-color);
  color: var(--grey-color);
  border: 1px solid var(--grey-color-light);
  padding: 0 20px;
}
.navbar_content {
  display: flex;
  align-items: center;
  column-gap: 25px;
}
.navbar_content i {
  cursor: pointer;
  font-size: 20px;
  color: var(--grey-color);
  display: none;
}
/* sidebar */
.sidebar {
  background-color: var(--white-color);
  width: 260px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  padding: 80px 20px;
  z-index: 100;
  overflow-y: scroll;
  box-shadow: 0 0 1px var(--grey-color-light);
  transition: all 0.5s ease;
}
.sidebar.close {
  padding: 60px 0;
  width: 80px;
}
.sidebar::-webkit-scrollbar {
  display: none;
}
.menu_content {
  position: relative;
}
.menu_title {
  margin: 15px 0;
  padding: 0 20px;
  font-size: 18px;
}
.sidebar.close .menu_title {
  padding: 6px 30px;
}
.menu_title::before {
  color: var(--grey-color);
  white-space: nowrap;
}
.menu_dahsboard::before {
  content: "Dashboard";
}
.menu_editor::before {
  content: "VIGAZ";
}
.menu_setting::before {
  /* content: "Taps"; */
}
.menu_media::before {
  /* content: "Media Center"; */
}
.sidebar.close .menu_title::before {
  content: "";
  position: absolute;
  height: 2px;
  width: 18px;
  border-radius: 12px;
  background: var(--grey-color-light);
}
.menu_items {
  padding: 0;
  list-style: none;
}
.navlink_icon {
  position: relative;
  font-size: 22px;
  min-width: 50px;
  line-height: 40px;
  display: inline-block;
  text-align: center;
  border-radius: 6px;
}
.navlink_icon::before {
  content: "";
  position: absolute;
  height: 100%;
  width: calc(100% + 100px);
  left: -20px;
}
.navlink_icon:hover {
  background: var(--blue-color);
}
.sidebar .nav_link {
  display: flex;
  align-items: center;
  width: 100%;
  /* padding: 4px 15px; */
  padding: 4px 2px;
  border-radius: 8px;
  text-decoration: none;
  color: var(--grey-color);
  white-space: nowrap;
}
.sidebar.close .navlink {
  display: none;
}
.nav_link:hover {
  color: var(--white-color);
  background: var(--blue-color);
}
.sidebar.close .nav_link:hover {
  background: var(--white-color);
}
.submenu_item {
  cursor: pointer;
}
.submenu {
  display: none;
}
.submenu_item .arrow-left {
  position: absolute;
  right: 10px;
  display: inline-block;
  margin-right: auto;
}
.sidebar.close .submenu {
  display: none;
}
.show_submenu ~ .submenu {
  display: block;
}
.show_submenu .arrow-left {
  transform: rotate(90deg);
}
.submenu .sublink {
  padding: 15px 15px 15px 52px;
}
.bottom_content {
  position: fixed;
  bottom: 60px;
  left: 0;
  width: 260px;
  cursor: pointer;
  transition: all 0.5s ease;
}
.bottom {
  position: absolute;
  display: flex;
  align-items: center;
  left: 0;
  justify-content: space-around;
  padding: 18px 0;
  text-align: center;
  width: 100%;
  color: var(--grey-color);
  border-top: 1px solid var(--grey-color-light);
  background-color: var(--white-color);
}
.shownav{
  display: block!important;
}
.bottom i {
  font-size: 20px;
}
.bottom span {
  font-size: 18px;
}
.sidebar.close .bottom_content {
  width: 50px;
  left: 15px;
}
.sidebar.close .bottom span {
  display: none;
}
.sidebar.hoverable .collapse_sidebar {
  display: none;
}
#sidebarOpen {
  display: none;
}
@media screen and (max-width: 768px) {
  #sidebarOpen {
    font-size: 25px;
    display: block;
    margin-right: 10px;
    cursor: pointer;
    color: var(--grey-color);
  }
  .sidebar.close {
    left: -100%;
  }
  .search_bar {
    display: none;
  }
  .sidebar.close .bottom_content {
    left: -100%;
  }
}





/* ---- Universal Css ---  */
:root{
    --main-bg : #E4E9F7;
  
    --light-white: #f0f0f0;
    --light-white-p: #6c757d;
    --color-main: #2E3F97;  
    --color-main-light: #53b6fd;  
    --color-anchor: #0d6efd;
    --color-blue: #4d8aff;
    --color-dark-blue: #004269;
    --color-blu : #4387c0;
    --color-blue-light : #00AAA9;
    --color-orange : orange;
    --color-green : rgb(44, 185, 16);
  
    --border-color-1 : rgba(101, 119, 134, 0.36);
  }
  
  a{
    text-decoration: none !important;
  }
  ol, ul {
    padding-left: 0rem !important;
    margin-bottom: 0rem !important;
    list-style: none;
  }
  
.breadCmb{
    padding: 1rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: white;;
  }
  .breadCmb h2{
    color: var(--color-main);
  }
  .breadCmb ul{
    display: flex;
  }

  .btn1 a{
    color: var(--color-main);
    text-transform: uppercase;
    text-decoration: none;
    border: 2px solid var(--color-main);
    padding: 10px 20px;
    font-size: 17px;
    font-weight: 600;
    background: transparent;
    position: relative;
    transition: all 1s;
    overflow: hidden;
    display: inline-block;
  }
  .btn1 a:hover{
    color: white !important;
    background-color: #004269;
  }
  
  
  .tables{
    margin: 2rem 0rem;
    background: white;
    padding: 0.5rem;
  }
  .tables tbody img{
    height: 75px;
    width: 75px;
    object-fit: contain;
  }
  .tables tbody .upd-btns{
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  .tables tbody .upd-btns .upd-btns{
    padding: 5px;
    color: white;
    background: red;
    border: none;
    outline: none;
  }
  .tables tbody .upd-btns .upd-btns.update{
    background: rgb(44, 185, 16);
  }
  .forms {
    margin: 2rem 0rem;
    background: white;
    padding:1rem;
  }
  .forms form>div{
    padding-bottom: 1rem;
  }
  .forms form textarea{
    height: 100px;
  }
  .ImgSmall{
    object-fit: contain;
    width: 5rem;
    background: #e3e3ee;
    padding: 0.2rem;
    height: 5rem;
  }
  
  
  
  .small-table table {
    font-size: 0.9rem;
    min-width: 3200px;
  }
  .small-table table th{
    width: max-content;
    font-weight: 500;
  }
@media screen and (max-width: 768px){
  .breadCmb {
    padding: 1rem 1rem;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 1rem;
    background: white;
}
}