/* @import url('https://fonts.googleapis.com/css2?family=Chewy&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  width: 100%;
  height: 100%;
  font-family: 'Poppins', sans-serif !important;
  scroll-behavior: smooth;
  overflow-x: hidden;
}


:root { 
  --color-green: #6abc21;
  --color-green-light: #e7f3df;

  --color-black: #1A1B1E;
  --color-orange: #ff7004;
  --color-brown: #5a3011;

  --bg-white-nav : #e2e2e2;
  --bg-beg: #f8eaea;
  --bg-white: #f3f5f6;
  --bg-yellow: #ffe023;
  --bg-yellow-light: #ffffee;
  --bg-yellow-dark: #ffffdd;

  --bg-white-light: #f8f8fc;

  --border-1 : 1px solid #dcdcdc;
  --transition-3: all 300ms ease-in;
  --transition-4: all 400ms ease-in;

  --font-chewy: "Chewy", system-ui;

}
a {
  text-decoration: none;
}



.container{
  padding: 2rem 0rem;
}

.grid-4{
  grid-template-columns: repeat(4,1fr);
  display: grid;
  gap: 0.5rem;
}


@media only screen and (max-width: 1000px) {
  .grid-4{
    grid-template-columns: repeat(3,1fr);
  }
}
@media only screen and (max-width: 600px) {
  .grid-4{
    grid-template-columns: repeat(2,1fr);
  }
}
@media only screen and (max-width: 400px) {
  .grid-4{
    grid-template-columns: repeat(1,1fr);
  }
}

/* Button 1  */
.button1 { 
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
  margin: 1rem 0rem;
 }
 
 .button1.learn-more {
  width: 12rem;
  height: auto;
 }
 
 .button1.learn-more .circle {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 3rem;
  height: 3rem;
  background: #282936;
  border-radius: 1.625rem;
 }
 
 .button1.learn-more .circle .icon {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #fff;
 }
 
 .button1.learn-more .circle .icon.arrow {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.625rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
 }
 
 .button1.learn-more .circle .icon.arrow::before {
  position: absolute;
  content: "";
  top: -0.29rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid #fff;
  border-right: 0.125rem solid #fff;
  transform: rotate(45deg);
 }
 
 .button1.learn-more .button-text {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.75rem 0;
  margin: 0 0 0 1.85rem;
  color: #282936;
  font-weight: 600;
  line-height: 1.6;
  text-align: center;
  text-transform: uppercase;
 }
 
 .button1:hover .circle {
  width: 100%;
 }
 
 .button1:hover .circle .icon.arrow {
  background: #fff;
  transform: translate(1rem, 0);
 }
 
 .button1:hover .button-text {
  color: #fff;
 }




 /* ---- underline-head ---  */
.underline-head h3{
  margin-bottom: 0rem;
}
.underline-head .line{
  height: 2px;
  width: 25%;
  background: var(--bg-yellow);
  display: inline-block;
  position: relative;
}
.underline-head .line::after{
  content: "";
  position: absolute;
  height: 300%;
  width: 30%;
  background-color: var(--color-brown);
  bottom: 0%;
  left: 0;
}




/* ==================== Whatsapp =================  */
.whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 8%;
  right: 5%;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  /* font-size: 30px; */
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;

  transform: all .5s ease;
  background-color: #25d366;
  line-height: 50px;
  text-decoration: none;

}

.whatsapp_float::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 50px;
  height: 50px;
  background-color: #25d366;
  border-radius: 50%;
  -webkit-animation: pulse-border 1500ms ease-out infinite;
  animation: pulse-border 1500ms ease-out infinite;
  box-shadow: 0px 0px 15px 3px rgba(71, 254, 126, 0.5);
}

.whatsapp_float:focus {
  border: none;
  outline: none;
}

.whatsapp-icon {
  margin-top: 16px;
}

@keyframes pulse-border {
  0% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
      opacity: 1;
  }

  100% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
      opacity: 1;
  }
}

/* for mobile */
@media screen and (max-width: 767px) {
  .whatsapp-icon {
      margin-top: 0px;
      /* margin-top: 10px; */
  }

  .whatsapp_float {
      width: 40px;
      height: 40px;
      bottom: 11%;
      right: 8%;
      font-size: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
  }
  .whatsapp_float::before {
      width: 40px;
      height: 40px;
      background-color: #25d366;
  }
}

.about-us-p strong{
  font-weight: 500;
  color: var(--color-green);
}

.about-us-p ul{
  padding-left: 2rem !important;
  list-style-type: disc;
}
.singleCate-flex .wardrobe:nth-child(even) .col-md-8{
  order: 1;
}

/* ------- col-3 imag-grid ---  */
.col-3.imag-grid img{
  height: 300px;
  object-fit: cover;
}
@media only screen and (max-width: 500px){
  .col-3.imag-grid img{
    height: 150px;
    object-fit: cover;
  }
}