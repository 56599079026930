/* .doorCategory .grid-4{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 0.2rem;
} */

.single-door{
    border-right: 1px solid var(--color-brown);
    transition: var(--transition-3);
    padding: 0.5rem;
}
.single-door:hover{
    background: var(--bg-beg);
}
.doorCategory .grid-4 .head {
    display: flex;
    align-items: center;
    text-decoration: none;
    margin-bottom: 0.5rem;
}
.doorCategory .grid-4 .head img{
    height: 2rem;
}
.doorCategory .grid-4 .head h4{
    margin-bottom: 0rem;
    padding-left: 0.5rem;
    text-transform: uppercase;
    font-size: 1.1rem;
    color: var(--color-brown);
}







